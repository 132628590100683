<template>
  <div v-if="authUserIsCoach">
    <LoadingMessage v-if="isLoading" :message="'Fetching metrics...'"></LoadingMessage>

    <div v-else>
      <form @submit.prevent v-if="!isLoading" class="d-flex align-items-center bg-dark p-2 rounded">
        <div class="form-floating-cta-container">
          <div v-if="activeGroup" class="h5 m-0 px-2 text-white d-flex align-items-center">
            <fa-icon :icon="['fas', 'folder-open']" class="me-2"></fa-icon>
            <div>{{ activeGroup.name }}</div>
          </div>
          <div v-else class="h5 m-0 px-2 text-white">Metrics</div>
        </div>
        <BootstrapDropdownButton
          :btnIcon="'plus'"
          :btnClass="'btn btn-primary ms-2'"
          :btnHideToggle="true"
          :btnDropdownDir="''"
        >
          <li
            @click="showAddModal = true"
            class="dropdown-item small"
            role="button"
          >Add metric</li>
          <li
            @click="showAddGroupModal = true"
            :class="{ disabled: activeGroup }"
            class="dropdown-item small"
            role="button"
          >Add group</li>
        </BootstrapDropdownButton>
        <IconButton
          @click="fetchItems"
          :icon="'sync-alt'"
          class="btn btn-primary ms-2"
        ></IconButton>
      </form>

      <div v-if="!isLoading" class="rounded border bg-white mt-2">
        <div v-if="metricGroups.length || metricItemsOnRoot.length"
        class="overflow-hidden">
          <div :class="{ 'is-busy': isMoving }">
            <ul v-if="!activeGroup" class="list-group list-group-flush">
              <!-- ROOT DIR: GROUPS -->
              <MetricListGroup
                @update="editGroup(group)"
                @enter="enterGroup(group)"
                v-for="(group, groupIdx) in metricGroups"
                :key="`key-${groupIdx}`"
                :group="group"
                class="list-group-item px-3"
              ></MetricListGroup>

              <!-- ROOT DIR: METRICS -->
              <MetricListItem
                @update="editMetric(metric)"
                @select="selectMetric(metric)"
                @deselect="deselectMetric(metric)"
                v-for="(metric, metricIdx) in metricItemsOnRoot"
                :key="`key-${metricIdx}-${metric.metricId}`"
                :metric="metric"
                :selectedItems="selectedItems"
                class="list-group-item px-3"
              ></MetricListItem>
            </ul>

            <!-- GROUP CONTENT -->
            <transition name="slide-left">
              <div v-if="activeGroup">
                <div class="p-3 border-bottom bg-white position-sticky back-container">
                  <IconButton
                    @click="leaveGroup"
                    :icon="'chevron-left'"
                    :text="'Back'"
                    class="btn btn-primary"
                  ></IconButton>
                </div>
                <ul
                  v-if="metricItemsOnGroup(activeGroup.metricGroupId).length"
                  class="list-group list-group-flush"
                >
                  <MetricListItem
                    @update="editMetric(metric)"
                    @select="selectMetric(metric)"
                    @deselect="deselectMetric(metric)"
                    v-for="(metric, metricIdx) in metricItemsOnGroup(activeGroup.metricGroupId)"
                    :key="`key-${activeGroup.metricGroupId}-${metricIdx}`"
                    :metric="metric"
                    :selectedItems="selectedItems"
                    class="list-group-item px-3"
                  ></MetricListItem>
                </ul>
                <div v-else class="py-2 px-3">
                  No metrics found
                </div>
              </div>
            </transition>
          </div>

          <div class="list-group-item list-group-item-footer border-0 border-top">
            <div class="d-flex align-items-center">
              <div class="form-check m-0 me-3 flex-grow-1">
                <input
                  @change="toggleSelectAll"
                  :disabled="isSelectDisabled || isMoving"
                  v-model="selectAll"
                  class="form-check-input"
                  type="checkbox"
                  id="select-all"
                >
                <label class="form-check-label" for="select-all">Select all</label>
              </div>
              <div v-if="isMoving" class="d-flex align-items-center me-3 small text-muted">
                <span
                  class="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span class="fst-italic">Please wait...</span>
              </div>
              <BootstrapDropdown
                :btnClass="'btn btn-sm btn-primary'"
                :btnText="`Move${selectedItemsCount ? ` ${selectedItemsCount}` : ''} to...`"
                :btnDisabled="!selectedItems.length || isMoving"
              >
                <li v-if="!metricGroups.length" class="dropdown-item small disabled">
                  No groups found
                </li>
                <li
                  @click="moveItems(group)"
                  v-for="(group, groupIdx) in metricGroups"
                  :key="`key-${groupIdx}`"
                  :class="{
                    disabled: activeGroup && activeGroup.metricGroupId === group.metricGroupId
                  }"
                  class="dropdown-item small"
                  role="button"
                >
                  <fa-icon :icon="['fas', 'folder']" class="me-1"></fa-icon>
                  {{ group.name }}
                </li>
                <li v-if="activeGroup" class="dropdown-divider"></li>
                <li
                  @click="moveItems()"
                  v-if="activeGroup"
                  class="dropdown-item small"
                  role="button"
                >
                  <fa-icon :icon="['fas', 'level-up-alt']" class="me-1"></fa-icon>
                  Root directory
                </li>
              </BootstrapDropdown>
            </div>
          </div>
        </div>
        <div v-else class="list-group-item border-0">
          No metrics found
        </div>
      </div>

      <portal to="modal">
        <transition name="modal">
          <MetricModal
            @close="hideModal"
            v-model="showAddModal"
            v-if="showAddModal"
            :metric="selectedItem"
            :metricGroup="activeGroup"
          ></MetricModal>
        </transition>
      </portal>

      <portal to="modal">
        <transition name="modal">
          <MetricGroupModal
            @close="hideModal"
            v-model="showAddGroupModal"
            v-if="showAddGroupModal"
            :metricGroup="selectedItem"
          ></MetricGroupModal>
        </transition>
      </portal>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

const initialState = () => ({
  isLoading: false,
  isMoving: false,
  activeGroup: null,
  selectAll: false,
  selectedItem: null,
  selectedItems: [],
  showAddModal: false,
  showAddGroupModal: false,
});

export default {
  name: 'MetricList',
  components: {
    LoadingMessage: () => import('@/components/message/LoadingMessage'),
    IconButton: () => import('@/components/button/IconButton'),
    BootstrapDropdown: () => import('@/components/bootstrap/BootstrapDropdown'),
    BootstrapDropdownButton: () => import('@/components/bootstrap/BootstrapDropdownButton'),
    MetricListItem: () => import('./MetricListItem.vue'),
    MetricListGroup: () => import('./MetricListGroup.vue'),
    MetricModal: () => import('./MetricModal.vue'),
    MetricGroupModal: () => import('./MetricGroupModal.vue'),
  },
  computed: {
    ...mapGetters('auth', ['authUserIsCoach']),
    ...mapState('auth', ['authUser']),
    ...mapState('metric', ['metricGroups']),
    ...mapGetters('metric', ['metricItemsOnRoot', 'metricItemsOnGroup']),
    isSelectDisabled() {
      const { activeGroup } = this;
      if (activeGroup) {
        return !this.metricItemsOnGroup(activeGroup.metricGroupId).length;
      }
      return !this.metricItemsOnRoot.length;
    },
    selectedItemsCount() {
      const { selectedItems } = this;
      if (selectedItems.length === 0) {
        return null;
      }
      return `${selectedItems.length} item${selectedItems.length > 1 ? 's' : ''}`;
    },
  },
  watch: {
    selectedItems(newVal) {
      const { activeGroup } = this;
      if (newVal.length > 0) {
        if (activeGroup) {
          const items = this.metricItemsOnGroup(activeGroup.metricGroupId);
          this.selectAll = newVal.length === items.length;
        } else {
          this.selectAll = newVal.length === this.metricItemsOnRoot.length;
        }
      }
    },
  },
  methods: {
    reset() {
      Object.assign(this.$data, initialState());
    },
    async fetchItems() {
      this.reset();
      this.isLoading = true;
      try {
        await this.$store.dispatch('metric/fetchItems');
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isLoading = false;
      }
    },
    async moveItems(targetGroup = null) {
      this.isMoving = true;
      try {
        const payload = {
          metricIds: this.selectedItems,
          metricGroupId: targetGroup ? targetGroup.metricGroupId : null,
          authorId: this.authUser.userId,
        };
        await this.$store.dispatch('metric/moveItem', payload);
        this.clearSelection();
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isMoving = false;
      }
    },

    // GROUP
    enterGroup(group) {
      this.clearSelection();
      this.activeGroup = group;
    },
    leaveGroup() {
      this.clearSelection();
      this.activeGroup = null;
    },
    editGroup(group) {
      this.selectedItem = group;
      this.showAddGroupModal = true;
    },

    // METRIC
    editMetric(metric) {
      this.selectedItem = metric;
      this.showAddModal = true;
    },
    selectMetric(metric) {
      const { metricId } = metric;
      const idx = this.selectedItems.indexOf(metricId);
      if (idx === -1) this.selectedItems.push(metricId);
    },
    deselectMetric(metric) {
      const { metricId } = metric;
      const idx = this.selectedItems.indexOf(metricId);
      if (idx > -1) this.selectedItems.splice(idx, 1);
    },

    // SELECTION
    clearSelection() {
      this.selectedItems = [];
      this.selectAll = false;
    },
    toggleSelectAll() {
      const { selectAll, activeGroup } = this;
      if (selectAll) {
        if (activeGroup) {
          this.selectedItems = this.metricItemsOnGroup(activeGroup.metricGroupId)
            .map((metric) => metric.metricId);
        } else {
          this.selectedItems = this.metricItemsOnRoot.map((metric) => metric.metricId);
        }
      } else {
        this.clearSelection();
      }
    },
    hideModal() {
      this.showAddModal = false;
      this.showAddGroupModal = false;
      this.selectedItem = null;
    },
  },
  data() {
    return initialState();
  },
  mounted() {
    this.fetchItems();
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/vars.scss";
.list-group-item {
  background: transparent;
}

.list-group-item.list-group-item-footer {
  background-color: rgba(0, 0, 0, .03);
  border-bottom-left-radius: $borderRadius;
  border-bottom-right-radius: $borderRadius;
}

.back-container {
  border-top-left-radius: $borderRadius;
  border-top-right-radius: $borderRadius;
}
</style>
